@use 'material' as *;
@use 'config' as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

$bg-basic-clr: #e3e3e3;

body {
  font-family: 'Roboto', "Helvetica Neue", sans-serif;
  line-height: 1.4rem;
  @apply text-lloyd
}

button {
  @apply cursor-pointer
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

body[data-location="gm"] {
  header {
    @apply bg-black;
    app-user-menu button {
      @apply text-white;
    }
  }
}

body[data-location="knsh"] {
  header {
    background-color: #202020;
    app-user-menu button {
      @apply text-white;
    }
  }
}
